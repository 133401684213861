// LoginForm.tsx
import React, { useEffect, useState } from 'react';

import {
  createBusinessPublic,
  getUserByEncryptedId,
  startEnrolationPublic,
  validateCredentialsPublic,
} from '../../../../lib/api';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useParams } from 'react-router-dom';
import { validaRut } from '../../../../lib/functions/utils';

const Login = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const [termsAgree, setTermsAgree] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [userData, setUserData] = useState({ name: '', company: { id: 0 } });
  const [password, setPassword] = useState('');
  const { id } = useParams<{ id: string }>();

  const validation = () => {
    if (!companyName.length) {
      alert('Debe poner un nombre de Cliente');
      return false;
    }
    if (!password.length) {
      alert('Debe poner una contraseña');
      return false;
    }
    if (!termsAgree) {
      alert('Debe aceptar los términos y condiciones.');
      return false;
    }
    if (identifier.includes(' ') || identifier.includes('.')) {
      alert('El rut no debe contener puntos ni espacios');
      return false;
    }
    if (!validaRut(identifier)) {
      alert('Rut inválido!');
      return false;
    }
    return true;
  };
  const resetForm = async () => {
    setCompanyName('');
    setIdentifier('');
    setPassword('');
  };

  const newSubmit = async () => {
    try {
      setLoading(true);
      if (!validation()) return setLoading(false);

      console.log('id', id);

      await validateCredentialsPublic({
        taxPassword: password,
        taxIdentifier: identifier,
        id,
      });
      const response = await createBusinessPublic({
        name: companyName,
        identifier: identifier,
        taxPassword: password,
        enrolledPath: 'public',
        id,
      });
      await startEnrolationPublic({
        taxPassword: password,
        taxIdentifier: identifier,
        businessId: response.id,
        id,
      });
      setOpen(true);
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 429) {
        alert('Solo puedes enrolar a 10 usuarios por hora');
      } else {
        alert(error.response.data.message);
      }
    }
    setLoading(false);
    setTermsAgree(false);
    resetForm();
    setLoading(false);
  };

  const fetchFormData = async () => {
    try {
      if (!id) return;

      const userResponse = await getUserByEncryptedId({ id });
      console.log(userResponse);
      setUserData(userResponse.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setInvalid(true);
    }
  };
  useEffect(() => {
    fetchFormData();
  }, []);

  if (loading) return <CenteredCircularProgress type="login" />;
  if (invalid) return <p>Enlace inválido</p>;

  return (
    <div>
      <Grid>
        <Grid>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className="div-container">
                <img
                  width={200}
                  src={`https://pruff-public.s3.amazonaws.com/company-logos/${[userData.company.id]}.png`}
                  alt="Logo"
                />
              </div>
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="p">
                Ingresa tus credenciales del SII
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nombre Cliente"
                      variant="outlined"
                      id="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="identifier"
                      label="RUT Cliente"
                      placeholder="Ej: 12345678-9"
                      variant="outlined"
                      value={identifier}
                      onChange={(e) =>
                        setIdentifier(e.target.value.toUpperCase())
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Contraseña SII"
                      fullWidth
                      type="password"
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <Checkbox
                        onChange={() => setTermsAgree(!termsAgree)}
                        value="allowExtraEmails"
                        color="primary"
                      />

                      <Typography variant="body1">
                        He leído y estoy de acuerdo con los{' '}
                        <span
                          onClick={() => {
                            window.open(
                              'https://pruff-public.s3.amazonaws.com/documents/Terminos%2By%2BCondiciones.pdf',
                            );
                          }}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          términos y condiciones
                        </span>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  onClick={() => newSubmit()}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Guardar
                </Button>
                <Typography fontSize={12}>
                  <b>Importante</b> Este form fue solicitado por el ejecutivo{' '}
                  <b>{userData.name}</b>. Si no conoces a esta persona, verifica
                  con tu ejeuctivo que este sea el sitio correcto.
                </Typography>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
      {open && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9999 /* Ensure it's above all other elements */,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor:
              'rgba(0, 0, 0, 0.3)' /* Semi-transparent background overlay */,
          }}
        >
          <Alert onClose={() => setOpen(false)} severity="success">
            <AlertTitle>Éxito</AlertTitle>
            Se ha enviado tu información con éxito. Tu ejecutivo se pondrá en
            contacto contigo a la brevedad.
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Login;
