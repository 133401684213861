import React, { useState } from 'react';
import EnhancedTable from '../../../../../../../../../../../lib/designSystem/Table';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../../../../../../../../lib/functions/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../../../../services/redux/authSlice';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../../../../lib/designSystem/HeaderWithActions';
import {
  getFactoringBalanceByPayers,
  getQuoteAppliedDocuments,
} from '../../../../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import ConfirmationModule from './components/confirmationModule';
import { EXECUTED_STAGES } from '../..';
import { Receipt, Upload } from '@mui/icons-material';
import Uploader from '../../../../../../../../../../../lib/designSystem/Uploader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import {
  appliedHeaders,
  documentssHeaders,
  payersHeaders,
} from './tableHeaders';
import {
  downloadFilesFromRequest,
  uploadFilesFromRequest,
} from '../../../../../../../../../../../lib/api/files';

const DocumentsDetailsModule = (props: any) => {
  const {
    selectedRequest,
    setSelectedRequest,
    setOpenShoppingCart,
    setSuccessOpen,
    setFailureOpen,
    resetShoppingCart,
  } = props;

  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [tableInView, setTableInView] = useState('documents');
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [openConfirmationModule, setOpenConfirmationModule] = useState(false);
  const [concentrationsByPayers, setConcentrationsByPayers] = useState<any[]>(
    [],
  );
  const [documentsApplied, setDocumentsApplied] = useState<any[]>([]);
  const [openUploadDocumentPDF, setOpenUploadDocumentPDF] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [documentToUpload, setDocumentToUpload] = useState('');

  const dispatch = useDispatch();

  const getStakeholderData = () => {
    if (selected.length) {
      const stakeholder = selectedRequest.documents.find(
        (document: any) => document.id === selected[0],
      );
      return {
        stakeholderIdentifier: stakeholder.stakeholderIdentifier,
        stakeholderName: stakeholder.stakeholderName,
      };
    }
    return { stakeholderIdentifier: '', stakeholderName: '' };
  };

  const { stakeholderIdentifier } = selected && getStakeholderData();

  const cantSelectRow = (row: any) => {
    if (
      stakeholderIdentifier &&
      row.stakeholderIdentifier !== stakeholderIdentifier
    )
      return true;
    if (row.confirmType === 'Post verificado') return false;
    if (row.confirmType) return true;

    if (!validatePermissions(user, 'financing_factoring_confirm_document')) {
      return true;
    }

    return false;
  };

  const allConfirmed = () => {
    if (!selected.length) return true;
    return false;
  };

  const handleDocumentsApplied = async () => {
    setLoading(true);
    try {
      let documentResponse = [];
      // if (!selectedRequest.quoteActive) {
      //   console.log('selectedRequest', selectedRequest);
      //   return;
      //   documentResponse = selectedRequest.invoiceToApply;
      // } else {
      documentResponse = await getQuoteAppliedDocuments(dispatch, {
        quoteId: selectedRequest.quoteActive.id,
      });
      // }
      console.log(documentResponse);
      setDocumentsApplied(documentResponse);
      setTableInView('applied');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updatePayersWithActualRequest = (payers: any) => {
    console.log('payers', payers);

    const documentsPayersSet = new Set(
      selectedRequest.documents.map((x: any) => x.stakeholderIdentifier),
    );
    const payersUpdate = [...payers];
    for (const payer of documentsPayersSet) {
      if (!payers.find((x: any) => x.stakeholderIdentifier === payer)) {
        payersUpdate.push({
          stakeholderIdentifier: payer,
          stakeholderName: selectedRequest.documents.find(
            (x: any) => x.stakeholderIdentifier === payer,
          ).stakeholderName,
          documentsCount: 0,
          totalDocumentsCount: 0,
          amount: 0,
          totalAmount: 0,
        });
      }
    }

    const newConcentrationsByPayers = payersUpdate.map((payer: any) => {
      const documents = selectedRequest.documents.filter(
        (x: any) => x.stakeholderIdentifier === payer.stakeholderIdentifier,
      );
      const amount = documents.reduce(
        (acc: any, inv: any) => acc + inv.amount,
        0,
      );
      const documentsCount = documents.length;
      const addAmount = !EXECUTED_STAGES.includes(selectedRequest.status);
      return {
        ...payer,
        documentsCount,
        totalDocumentsCount: addAmount
          ? payer.documentsCount + documentsCount
          : payer.documentsCount,
        amount,
        totalAmount: addAmount ? payer.totalAmount + amount : payer.totalAmount,
      };
    });
    return newConcentrationsByPayers;
  };

  const handlePayers = async () => {
    setLoading(true);
    try {
      console.log('selectedRequest', selectedRequest);
      const response = await getFactoringBalanceByPayers(dispatch, {
        identifier: selectedRequest.businessIdentifier,
      });
      const updatedPayers = updatePayersWithActualRequest(
        response.stakeholderConcentrationUses,
      );
      console.log('updatedPayers', updatedPayers);
      setConcentrationsByPayers(updatedPayers);
      setTableInView('payers');
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDocuments = () => {
    console.log(selectedRequest.documents);
    setTableInView('documents');
  };

  const canSelect = () => {
    if (selectedRequest.status === 'Creando') return false;
    return (
      validateCompanyTypes(user, 'Accountant') ||
      validateCompanyTypes(user, 'FactoringQuoting')
    );
  };

  const handleConfirmation = async () => {
    setOpenConfirmationModule(true);
  };

  const actionButtons = [
    {
      name: 'Documentos',
      action: () => handleDocuments(),
      color: 'secondary',
      disabled: tableInView === 'documents',
    },
    {
      name: '% Deudores',
      action: () => handlePayers(),
      color: 'secondary',
      disabled: tableInView === 'payers',
    },
    {
      name: 'Aplicados',
      action: () => handleDocumentsApplied(),
      color: 'secondary',
      disabled: tableInView === 'applied' || !selectedRequest.quoteActive,
    },
  ];

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    setUploadedFileName(uploadedFile.name);
    setFile(uploadedFile);
  };

  const uploadFiles = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      // Change the file Name to name prop
      const fileToUpload = new File([file], `${documentToUpload}.pdf`, {
        type: file.type,
      });
      formData.append('files', fileToUpload);
      await uploadFilesFromRequest(dispatch, formData, {
        requestId: documentToUpload,
        requestType: 'document',
      });
      setSuccessOpen('Se han subido tus archivos con éxito');
      setDocumentToUpload('');
      const documents = selectedRequest.documents.map((document: any) => {
        if (
          `${document.businessIdentifier}-${document.folio}` ===
          documentToUpload
        ) {
          return {
            ...document,
            fileStatus: true,
          };
        }
        return document;
      });
      setSelectedRequest({
        ...selectedRequest,
        documents,
      });
      setOpenUploadDocumentPDF(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUploadDocumentPDF = (documentId: string) => {
    setOpenUploadDocumentPDF(true);
    const document = selectedRequest.documents.find(
      (x: any) => x.id === documentId,
    );
    const documentKey = `${document.businessIdentifier}-${document.folio}`;
    setDocumentToUpload(documentKey);
  };

  const handleDownloadDocumentPDF = async (documentId: string) => {
    const document = selectedRequest.documents.find(
      (x: any) => x.id === documentId,
    );
    const documentKey = `${document.businessIdentifier}-${document.folio}`;
    setLoading(true);
    try {
      const data = {
        requestId: documentKey,
        fileName: `${documentKey}.pdf`,
        requestType: 'document',
      };
      const response = await downloadFilesFromRequest(dispatch, data);
      console.log(response);
      if (response) {
        window.open(response);
      } else {
        setFailureOpen('No hay archivo para descargar');
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUploadDocumentPDFObject = {
    key: 'uploadDocumentPDF',
    label: '',
    handleAction: handleUploadDocumentPDF,
    icon: <Upload />,
  };

  const handleDownloadDocumentPDFObject = {
    key: 'downloadDocumentPDF',
    label: '',
    handleAction: handleDownloadDocumentPDF,
    icon: <Receipt />,
    keyDisabled: 'fileStatus',
  };

  const generateButtons = () => {
    let buttons: CustomButtonProps[] = [];
    switch (tableInView) {
      case 'documents':
        buttons = [
          {
            name: 'Verificar',
            action: () => handleConfirmation(),
            color: 'primary',
            disabled:
              selectedRequest.status === 'Creando' ||
              allConfirmed() ||
              !validatePermissions(
                user,
                'financing_factoring_confirm_document',
              ),
          },
        ];
        break;
      default:
        return [];
    }
    return buttons;
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        titleButtons={actionButtons}
        buttons={generateButtons()}
      />
      {tableInView === 'applied' && (
        <EnhancedTable
          dataHeaders={appliedHeaders}
          data={documentsApplied}
          rowsPerPageDefault={5}
          finalSumRow
          exportToFile="xlsx"
        />
      )}
      {tableInView === 'documents' && (
        <EnhancedTable
          dataHeaders={[
            handleUploadDocumentPDFObject,
            handleDownloadDocumentPDFObject,
            ...documentssHeaders,
          ]}
          data={selectedRequest.documents}
          rowsPerPageDefault={5}
          finalSumRow
          selected={canSelect() ? selected : null}
          setSelected={setSelected}
          cantSelectRow={cantSelectRow}
          exportToFile="xlsx"
        />
      )}
      {tableInView === 'payers' && (
        <EnhancedTable
          dataHeaders={payersHeaders}
          data={concentrationsByPayers}
          rowsPerPageDefault={5}
          finalSumRow
          exportToFile="xlsx"
        />
      )}
      <div style={{ padding: '20px 0px' }}>
        {selected.length &&
        openConfirmationModule &&
        tableInView === 'documents' ? (
          <ConfirmationModule
            selectedRequest={selectedRequest}
            setOpenShoppingCart={setOpenShoppingCart}
            selected={selected}
            setSelected={setSelected}
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
            open={openConfirmationModule}
            setOpen={setOpenConfirmationModule}
            resetShoppingCart={resetShoppingCart}
            setSelectedRequest={setSelectedRequest}
          />
        ) : null}
      </div>
      {openUploadDocumentPDF && (
        <Dialog
          open={openUploadDocumentPDF}
          onClose={() => setOpenUploadDocumentPDF(false)}
        >
          <DialogTitle id="form-dialog-title">Subir factura</DialogTitle>
          <DialogContent style={{ minHeight: 100, padding: 50 }}>
            <Uploader
              buttonText="Subir documento"
              handleFileChange={handleFileChange}
              uploadedFileName={uploadedFileName}
              setUploadedFileName={setUploadedFileName}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => setOpenUploadDocumentPDF(false)}
              color="secondary"
            >
              Cancelar
            </CustomButton>
            <CustomButton onClick={uploadFiles} color="primary">
              Subir
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
      {!openUploadDocumentPDF &&
        ['documents', 'applied', 'payers'].includes(tableInView) && (
          <DialogActions>
            <CustomButton
              onClick={() => setOpenShoppingCart(false)}
              color="secondary"
            >
              Salir
            </CustomButton>
          </DialogActions>
        )}
    </div>
  );
};

export default DocumentsDetailsModule;
