import React, { useState, useEffect } from 'react';
import EnhancedTable from '../../../../lib/designSystem/Table';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../lib/designSystem/HeaderWithActions';
import { getActiveBusinessRelationsForUser } from '../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomAlert from '../../../../lib/designSystem/Alert';
import ConnectBusiness from './components/ConnectBusiness';
import EditBusiness from './components/EditBusiness';
import { AuthState } from '../../../../services/redux/authSlice';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../lib/functions/utils';
import ShowConnections from './components/ShowConections';
import EditConnection from './components/EditConnection';
import AddRelation from './components/AddRelation';
import { Autocomplete, Grid, TextField } from '@mui/material';

const nullCompanyState = {
  identifier: '',
  password: '',
  businessName: '',
  active: null,
};
export interface BusinessRelation {
  id?: number;
  identifier: string;
  password?: string;
  businessName: string;
  active?: boolean | null;
}

const Companies: React.FC = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openShowConnections, setOpenShowConnections] = useState(false);
  const [connection, setConnection] = useState({});
  const [filteredBusinesses, setFilteredBusinesses] = useState<
    BusinessRelation[]
  >([]);
  const [filterValue, setFilterValue] = useState<any>(null);
  const [dataCompanies, setDataCompanies] = useState<{
    relations: BusinessRelation[];
    ivaContainers: any[];
  }>({ relations: [], ivaContainers: [] });
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('false');
  const [myCompaniesActivated, setMyCompaniesActivated] = useState(false);

  const activateSuccess = (message: string) => {
    setSuccessMessage(message);
    setSuccess(true);
  };
  const activateFailed = (message: string) => {
    setFailedMessage(message);
    setFailed(true);
  };
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const [selectedBusiness, setSelectedBusiness] =
    useState<BusinessRelation>(nullCompanyState);

  const buttonsData: CustomButtonProps[] = [];

  const handleMyBusinessFilterForAdmin = () => {
    if (myCompaniesActivated) {
      setFilteredBusinesses(dataCompanies.relations);
      setMyCompaniesActivated(!myCompaniesActivated);
      return;
    } else {
      const filtered = dataCompanies.relations.filter((x: any) =>
        x.users.includes(user?.name),
      );
      setFilteredBusinesses(filtered);
    }
    setMyCompaniesActivated(!myCompaniesActivated);
  };
  if (validatePermissions(user, 'business_read_all')) {
    buttonsData.push({
      action: handleMyBusinessFilterForAdmin,
      name: myCompaniesActivated ? 'Todas' : 'Mis cuentas',
      color: 'secondary',
    });
  }

  if (validatePermissions(user, 'businesses_create_new'))
    buttonsData.push({
      action: () => handleOpenAddDialog(),
      name: 'Agregar',
      color: 'primary',
    });

  const handleNavigationSii = (id: number) => {
    console.log(id);
    const business = dataCompanies.relations.find((x: any) => x.id === id);
    if (!business) return alert('Ha ocurrido un error');

    if (!business.active) {
      window.location.href = `/companies/${id}/sii/taxFolder`;
    } else {
      if (validatePermissions(user, 'sii_read_profile')) {
        window.location.href = `/companies/${id}/sii/summary`;
      } else {
        window.location.href = `/companies/${id}/tgr/debts`;
      }
    }
  };

  const handleOpenAddDialog = () => {
    setSelectedBusiness(nullCompanyState);
    setOpenAddDialog(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (e.target.name === 'identifier') {
      value = value.toUpperCase();
    }
    setSelectedBusiness((prev: any) => ({ ...prev, [e.target.name]: value }));
  };

  const fetchRelations = async () => {
    setLoading(true);
    const results = await getActiveBusinessRelationsForUser(dispatch);
    setDataCompanies(results || []);
    setFilteredBusinesses(results.relations);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  const handleOpenEditDialog = (id: number) => {
    const business = dataCompanies.relations.find((x: any) => x.id === id);
    if (!business) return alert('Ha ocurrido un error');

    setSelectedBusiness(business);
    setOpenEditDialog(true);
  };

  const canShowOpenSharing = (id: number) => {
    const business = dataCompanies.relations.find((x: any) => x.id === id);
    if (!business) return alert('Ha ocurrido un error');

    if (!validatePermissions(user, 'financing_visibility_create')) return false;

    if (!validateCompanyTypes(user, 'Accountant')) return false;

    return true;
  };

  const handleOpenBusiness = async (id: number) => {
    const relationSelected = dataCompanies.relations.find(
      (x: any) => x.id === id,
    );
    if (!relationSelected) return alert('Ha ocurrido un error');
    setSelectedBusiness(relationSelected);
    setOpenShowConnections(true);
  };

  const canSeeBusiness = () => {
    const siiPermission = validatePermissions(user, 'sii_read_profile');
    const tgrPersmission = validatePermissions(user, 'tgr_read');
    const clientPermission = validatePermissions(user, 'client_read');
    const scoringPermission = validatePermissions(user, 'scoring_read');

    if (
      siiPermission ||
      tgrPersmission ||
      clientPermission ||
      scoringPermission
    )
      return true;
    return false;
  };

  const canEditBusiness = () => {
    const permission = validatePermissions(user, 'businesses_create_new');
    if (permission) return true;
    return false;
  };

  const actions = [
    {
      action: handleNavigationSii,
      actionLabel: 'Ver',
      canShow: canSeeBusiness,
    },
    {
      action: handleOpenBusiness,
      actionLabel: 'Compartir',
      canShow: canShowOpenSharing,
    },
    {
      action: handleOpenEditDialog,
      actionLabel: 'Editar',
      canShow: canEditBusiness,
    },
  ];
  const handleOpenAdd = () => {
    setAddOpen(true);
    setOpenShowConnections(false);
  };
  const handleOpenEdit = () => {
    setEditOpen(true);
    setOpenShowConnections(false);
  };

  const headers = [
    { key: 'businessName', label: 'Nombre' },
    { key: 'identifier', label: 'Rut' },
    { key: 'active', label: 'SII', type: 'sii-connection' },
    { key: 'lastExtraction', label: 'Última extracción', type: 'date-large' },
    { key: 'createdAt', label: 'Creado', type: 'date-large' },
  ];

  if (validatePermissions(user, 'business_read_all')) {
    const newList = [
      { key: 'users', label: 'Ejecutivos' },
      // { key: 'enrolledBy', label: 'Enrolado por' },
      // { key: 'enrolledPath', label: 'Enrolado en' },
    ];
    headers.push(...newList);
  }

  if (validateCompanyTypes(user, 'Accountant')) {
    const newObject = {
      key: 'vission',
      label: 'Compartiendo',
      type: 'vission',
    };
    headers.push(newObject);
  }

  const handleChange = (event: any, newValue: any) => {
    if (!newValue) {
      setFilterValue(null);
      return setFilteredBusinesses(dataCompanies.relations);
    }
    const filtered = dataCompanies.relations.filter(
      (x: any) => x.id === newValue.id,
    );
    setFilteredBusinesses(filtered);
    setFilterValue(newValue);
  };

  const AutoCompleteFilter = () => (
    <Autocomplete
      options={dataCompanies.relations}
      getOptionLabel={(option) =>
        `${option.businessName} - ${option.identifier}`
      }
      value={filterValue}
      renderInput={(params) => (
        <TextField style={{ minWidth: 250 }} {...params} label="Filtrar" />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  if (loading) return <CenteredCircularProgress />;
  return (
    <div>
      <HeaderWithActions
        type="subHeader"
        title="Estas son las cuentas que has agregado."
        variant="body1"
        buttons={buttonsData}
        item={
          <Grid container>
            <Grid item xs={12}>
              <AutoCompleteFilter />
            </Grid>
          </Grid>
        }
      />
      <EnhancedTable
        dataHeaders={headers}
        data={filteredBusinesses}
        showActions
        rowsPerPageDefault={50}
        actions={actions}
        exportToFile="xlsx"
      />
      {/* Add Cliente Dialog */}
      <ConnectBusiness
        setLoading={setLoading}
        dispatch={dispatch}
        activateSuccess={activateSuccess}
        setFailed={setFailed}
        setOpenEditDialog={setOpenEditDialog}
        handleInputChange={handleInputChange}
        fetchRelations={fetchRelations}
        selectedBusiness={selectedBusiness}
        openAddDialog={openAddDialog}
        setOpenAddDialog={setOpenAddDialog}
      />

      <EditBusiness
        business={selectedBusiness}
        setLoading={setLoading}
        dispatch={dispatch}
        setFailed={setFailed}
        activateSuccess={activateSuccess}
        activateFailed={activateFailed}
        setOpenEditDialog={setOpenEditDialog}
        openEditDialog={openEditDialog}
        handleInputChange={handleInputChange}
        fetchRelations={fetchRelations}
        selectedBusiness={selectedBusiness}
      />

      {addOpen && (
        <AddRelation
          open={addOpen}
          setOpen={setAddOpen}
          business={selectedBusiness}
          activateSuccess={activateSuccess}
          activateFailed={activateFailed}
          setSuccesText={activateSuccess}
          setUp={fetchRelations}
        />
      )}
      {editOpen && (
        <EditConnection
          open={editOpen}
          setOpen={setEditOpen}
          connection={connection}
          activateFailed={activateFailed}
          activateSuccess={activateSuccess}
          setFailed={setFailed}
          setSuccesText={activateSuccess}
          setUp={fetchRelations}
        />
      )}
      {openShowConnections && (
        <ShowConnections
          open={openShowConnections}
          setOpen={setOpenShowConnections}
          activateSuccess={activateSuccess}
          activateFailed={activateFailed}
          setConnection={setConnection}
          business={selectedBusiness}
          addOpen={addOpen}
          setUp={fetchRelations}
          setAddOpen={setAddOpen}
          handleOpenAdd={handleOpenAdd}
          handleOpenEdit={handleOpenEdit}
        />
      )}
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successMessage}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Error"
        text={failedMessage}
        severity="error"
      />
    </div>
  );
};

export default Companies;
