import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gettUserExecutives } from '../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import Step1 from './components/Step1';

import Step2 from './components/Step2';
import Step3 from './components/Step3';
import { useParams } from 'react-router-dom';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import { validateCompanyTypes } from '../../../../../../../lib/functions/utils';
import { getCompanyConfig } from '../../../../../../../lib/api/company';
import { expressGetCompanyConfig } from '../../../../../../../lib/api/express/company';
import { createExpressFactoringRequest } from '../../../../../../../lib/api/express';

const ShoppingCartFactoring = (props: any) => {
  const {
    business,
    selectedDocuments,
    setSelectedDocuments,
    openShoppingCart,
    setOpenShoppingcart,
    setSuccess,
    setFailed,
    setSuccesText,
    setFailedText,
    setContinueToSimulation,
    setSelectedRequest,
    subProductSelected,
    subProduct,
    setOpenDocumentsSelector,
    setBackwards,
    backwards,
    exchangeDay,
    setExchangeDay,
  } = props;
  const { id } = useParams<{ id: string }>();
  const [disabledSimulator, setDisabledSimulator] = useState(true);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [executivesForms, setExecutivesForms] = useState({
    executives: [],
  });
  const [requestPayers, setRequestPayers] = useState<any>([]);
  const [canForcePaymentDate, setCanForcePaymentDate] = useState(false);
  const [fileStatusMandatory, setFileStatusMandatory] = useState(false);
  const [minimumDays, setMinimumDays] = useState(15);
  const [files, setFiles] = useState<any>([]);
  const [executives, setExecutives] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempPaymentDateRows, setTempPaymentDateRows] = useState<any[]>(
    new Array(selectedDocuments.length).fill(null),
  );
  const [tempSubDocumentPaymentDateRows, setTempSubDocumentPaymentDateRows] =
    useState<any[]>(new Array(selectedDocuments.length).fill([]));
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    const files = Array.from(event.target.files);
    setFiles(files); // Pass the file list to the parent component
  };

  const [step, setStep] = useState(1);
  const handleCloseDialog = () => {
    setOpenShoppingcart(false);
    setStep(0);
    setFiles([]);
  };
  console.log('Business', business);

  const createSelectedRequestObject = () => {
    const documentsForRequest = selectedDocuments.map((document: any) => {
      const documents: any = [];
      if (document.subDocuments && document.subDocuments.length) {
        document.subDocuments.forEach((subDocument: any) => {
          documents.push({
            id: subDocument.id,
            amount: subDocument.amount,
            amountDocument: 0,
            amountFinanced: 0,
            amountRetention: 0,
            businessIdentifier: subDocument.businessIdentifier,
            issuedDate: subDocument.issuedDate,
            confirmDate: document.confirmDate,
            daysToCount: subDocument.daysToCount,
            folio: `${document.folio}-${subDocument.folio}`,
            subProductName: subProduct?.name,
            paymentDate: subDocument.paymentDate,
            cancelDate: subDocument.cancelDate,
            stakeholderName: subDocument.stakeholderName,
            stakeholderIdentifier: subDocument.stakeholderIdentifier,
            fatherDocumentId: document.id,
            active: true,
          });
        });
        return documents;
      } else {
        return [
          {
            id: document.id,
            amount: document.amount,
            amountDocument: 0,
            amountFinanced: 0,
            amountRetention: 0,
            businessIdentifier: document.businessIdentifier,
            issuedDate: document.issuedDate,
            daysToCount: document.daysToCount,
            confirmDate: document.confirmDate,
            cedded: 'No cedido',
            folio: document.folio,
            paymentDate: document.paymentDate,
            subProductName: subProduct?.name,
            cancelDate: document.cancelDate,
            stakeholderName: document.stakeholderName,
            stakeholderIdentifier: document.stakeholderIdentifier,
            active: true,
          },
        ];
      }
    });

    const documents = documentsForRequest.flat();

    const request = {
      id: null,
      advance: 0,
      rate: 0,
      businessIdentifier: documents[0].businessIdentifier,
      businessName: business?.businessName,
      amount: documents.reduce((acc: any, curr: any) => acc + curr.amount, 0),
      amounToApply: 0,
      operationCost: 0,
      status: 'Creando',
      subProduct: subProductSelected,
      subProductName: subProduct?.name,
      documents: documents,
    };
    setSelectedRequest(request);
  };

  const validateDocumentsAndSubDocuments = () => {
    const duplicatedFolios: string[] = [];
    selectedDocuments.forEach((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        const subFolios = document.oldSubDocuments.map(
          (subDocument: any) => subDocument.folio,
        );
        document.subDocuments.forEach((subDocument: any) => {
          const key = `${document.folio}-${subDocument.folio}`;
          if (subFolios.includes(key)) {
            duplicatedFolios.push(key);
          }
        });
      }
    });
    if (duplicatedFolios.length) {
      alert(`Ya existen algunos sub folios. ${duplicatedFolios.join(', ')}`);
      return false;
    }

    const paymentDate = selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.paymentDate;
        });
      } else {
        return document.paymentDate;
      }
    });
    if (!paymentDate) {
      alert('Por favor, complete las fechas de pago');
      return false;
    }
    const amount = selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.amount;
        });
      } else {
        return document.amount;
      }
    });
    if (!amount) {
      alert('Por favor, complete los montos');
      return false;
    }
    const amountSubDocumentsLessThanFather = selectedDocuments.every(
      (document: any) => {
        if (document.subDocuments && document.subDocuments.length) {
          return (
            document.subDocuments.reduce(
              (acc: any, curr: any) => acc + curr.amount,
              0,
            ) <= document.amount
          );
        } else {
          return true;
        }
      },
    );
    if (!amountSubDocumentsLessThanFather) {
      alert('El monto de los documentos no puede ser mayor al del padre');
      return false;
    }
    return true;
  };

  const executeAccountantCreateRequest = async () => {
    if (!user || !user.clientId)
      return alert('No se ha podido obtener el usuario');
    if (!checkSelectedDocumentFileStatus()) {
      alert('Por favor, suba los PDF de las facturas en la solicitud.');
      return;
    }
    setLoading(true);
    try {
      await createExpressFactoringRequest(dispatch, user.clientId, {
        requestsDocuments: selectedDocuments,
        subProductId: 'electronic-invoice',
        documentsToApply: [],
      });
      setSuccesText('Solicitud enviada con éxito');
      setSuccess(true);
      setOpenShoppingcart(false);
      window.location.href = `/factoring/requests`;
    } catch (error) {
      setFailedText(`${error}`);
      setFailed(true);
    }
    setLoading(false);
  };

  const handleFoward = async () => {
    if (step === 1) {
      if (validateCompanyTypes(user, 'FactoringQuoting')) {
        if (!validateDocumentsAndSubDocuments()) {
          return;
        }
        createSelectedRequestObject();
        setContinueToSimulation();
        setOpenShoppingcart(false);
        return;
      } else if (validateCompanyTypes(user, 'Accountant')) {
        await executeAccountantCreateRequest();
        return;
      }
    }
  };

  const handleBackwards = () => {
    if (validateCompanyTypes(user, 'Accountant')) {
      return setOpenShoppingcart(false);
    }
    setOpenDocumentsSelector(true);
    setOpenShoppingcart(false);
    setBackwards(true);
  };

  const stepChecker = () => {
    switch (step) {
      case 1:
        return !validatePaymentDays();
      case 2:
        return disabledSimulator;
    }
  };

  const validatePaymentDays = () => {
    return selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.paymentDate;
        });
      } else {
        return document.paymentDate;
      }
    });
  };

  const setUpRequestsInvoices = () => {
    const requestInvoicesArray = [];
    const payersObject: any = {};
    for (const document of selectedDocuments) {
      const paymentDate = document.paymentDate;
      const requestInvoice = { ...document, paymentDate };
      requestInvoicesArray.push(requestInvoice);
      if (!payersObject[requestInvoice.stakeholderIdentifier]) {
        payersObject[requestInvoice.stakeholderIdentifier] = {
          stakeholderName: requestInvoice.stakeholderName,
          paymentDays: null,
        };
      }
    }
    setSelectedDocuments(requestInvoicesArray);
    setRequestPayers(payersObject);
  };

  const setUp = async () => {
    setLoading(true);
    let executives;
    if (!id) {
      executives = [];
    } else {
      executives = await gettUserExecutives(dispatch);
    }
    setExecutives(executives || []);
    await fetchDateRestrictionsConfig();
    setUpRequestsInvoices();
    setLoading(false);
  };
  const getRestrictions = async () => {
    let system_operations_forcePaymentDate,
      system_operations_minimunDays,
      pruffConnect_operations_uploadPdfMandatory;
    if (user?.express) {
      if (!user.clientId) {
        return {
          system_operations_forcePaymentDate,
          system_operations_minimunDays,
          pruffConnect_operations_uploadPdfMandatory,
        };
      }
      ({
        system_operations_forcePaymentDate,
        system_operations_minimunDays,
        pruffConnect_operations_uploadPdfMandatory,
      } = await expressGetCompanyConfig(dispatch, user.clientId, [
        'system_operations_forcePaymentDate',
        'system_operations_minimunDays',
        'pruffConnect_operations_uploadPdfMandatory',
      ]));
    } else {
      system_operations_forcePaymentDate = await getCompanyConfig(
        dispatch,
        'system_operations_forcePaymentDate',
      );
      system_operations_minimunDays = await getCompanyConfig(
        dispatch,
        'system_operations_minimunDays',
      );
      pruffConnect_operations_uploadPdfMandatory = await getCompanyConfig(
        dispatch,
        'pruffConnect_operations_uploadPdfMandatory',
      );
    }
    return {
      system_operations_forcePaymentDate,
      system_operations_minimunDays,
      pruffConnect_operations_uploadPdfMandatory,
    };
  };

  const fetchDateRestrictionsConfig = async () => {
    try {
      const {
        system_operations_forcePaymentDate,
        system_operations_minimunDays,
        pruffConnect_operations_uploadPdfMandatory,
      } = await getRestrictions();

      setCanForcePaymentDate(Number(system_operations_forcePaymentDate) === 1);
      setFileStatusMandatory(
        Number(pruffConnect_operations_uploadPdfMandatory) === 1,
      );
      if (system_operations_minimunDays)
        setMinimumDays(Number(system_operations_minimunDays));
    } catch (error) {
      console.error('Error fetching date restrictions config', error);
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttonText = () => {
    if (validateCompanyTypes(user, 'Accountant')) {
      return 'Enviar solicitud';
    }
    if (validateCompanyTypes(user, 'FactoringQuoting')) {
      return 'Ir a simulador';
    }
  };

  const resetPaymentDates = () => {
    const requestsInvoicesWithDates = [];
    for (const requestInvoice of selectedDocuments) {
      const newRI = {
        ...requestInvoice,
        paymentDate: null,
        dueDate: null,
        daysToCount: 0,
      };
      if (requestInvoice.subDocuments) {
        for (const subDocument of newRI.subDocuments) {
          subDocument.paymentDate = null;
          subDocument.dueDate = null;
          subDocument.daysToCount = 0;
        }
      }
      requestsInvoicesWithDates.push(newRI);
    }
    const tempPaymentDateRows = new Array(selectedDocuments.length).fill(null);
    setTempPaymentDateRows(tempPaymentDateRows);
    const tempSubDocumentPaymentDateRows = selectedDocuments.map(
      (document: any) =>
        new Array(
          document.subDocuments ? document.subDocuments.length : 0,
        ).fill(null),
    );
    setTempSubDocumentPaymentDateRows(tempSubDocumentPaymentDateRows);
    setSelectedDocuments(requestsInvoicesWithDates);
  };

  const handleExchangeDay = () => {
    resetPaymentDates();
    setExchangeDay(!exchangeDay);
  };

  const checkSelectedDocumentFileStatus = () => {
    console.log('selectedDocuments', selectedDocuments);
    console.log('fileStatusMandatory', fileStatusMandatory);
    if (!fileStatusMandatory) return true;
    return selectedDocuments.every((document: any) => {
      return document.fileStatus;
    });
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openShoppingCart}
        onClose={() => handleCloseDialog()}
      >
        <DialogTitle>
          <span style={{ fontWeight: 'bold' }}>
            Solicitud de financiamiento
          </span>{' '}
          {/* (Paso {step}/{steps}) */}
        </DialogTitle>
        <div style={{ minHeight: 100 }}>
          {loading ? (
            <CenteredCircularProgress type="dialog" />
          ) : (
            <div>
              {step === 1 && (
                <Step1
                  selectedDocuments={selectedDocuments}
                  requestPayers={requestPayers}
                  setRequestPayers={setRequestPayers}
                  setSelectedDocuments={setSelectedDocuments}
                  exchangeDay={exchangeDay}
                  setTempPaymentDateRows={setTempPaymentDateRows}
                  tempPaymentDateRows={tempPaymentDateRows}
                  setTempSubDocumentPaymentDateRows={
                    setTempSubDocumentPaymentDateRows
                  }
                  tempSubDocumentPaymentDateRows={
                    tempSubDocumentPaymentDateRows
                  }
                  backwards={backwards}
                  canForcePaymentDate={canForcePaymentDate}
                  minimumDays={minimumDays}
                  fileStatusMandatory={fileStatusMandatory}
                />
              )}
              {step === 2 && (
                <Step2
                  selectedDocuments={selectedDocuments}
                  setDisabledSimulator={setDisabledSimulator}
                />
              )}
              {step === 3 && validateCompanyTypes(user, 'Accountant') && (
                <Step3
                  executives={executives}
                  files={files}
                  executivesForms={executivesForms}
                  setExecutivesForms={setExecutivesForms}
                  handleChangeFile={handleChange}
                />
              )}

              <DialogActions>
                {step === 1 && !user?.express && (
                  <CustomButton
                    onClick={() => handleExchangeDay()}
                    color="warning"
                  >
                    {exchangeDay ? 'Deshabilitar' : 'Habilitar'} canje
                  </CustomButton>
                )}
                <CustomButton
                  onClick={() => handleBackwards()}
                  color="secondary"
                >
                  {validateCompanyTypes(user, 'Accountant') ? 'Salir' : 'Atrás'}
                </CustomButton>
                <CustomButton
                  color="primary"
                  disabled={stepChecker()}
                  onClick={() => handleFoward()}
                >
                  {buttonText()}
                </CustomButton>
              </DialogActions>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ShoppingCartFactoring;
